<script setup>
  /* eslint-disable */
  import { Authenticator, AuthenticatorSignUpFormFields, useAuthenticator, AmplifyCheckBox, } from "@aws-amplify/ui-vue";
  import "@aws-amplify/ui-vue/styles.css";
  import { toRefs } from 'vue';

  const { validationErrors } = toRefs(useAuthenticator());

  const services = {
    async validateCustomSignUp(formData) {
      if (!formData.acknowledgement) {
        return {
          acknowledgement: 'You must agree to the Terms & Conditions',
        };
      }
    },
  };
</script>
<template>
  <div class="authForm">
    <authenticator :form-fields="formFields" :services="services" class="authenticator">
      <template v-slot:sign-up-fields>
        <authenticator-sign-up-form-fields />
        <amplify-check-box :errorMessage="validationErrors.acknowledgement" />
      </template>
    </authenticator>
  </div>
</template>
<script>
  import { Hub, Auth } from 'aws-amplify'; 

export default {
  data() {
    return {
      user: this.$store.getters.user || undefined,
      authState: undefined,
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false,
        },
      },
      formFields: {
          signIn: {
            username: {
              labelHidden: false,
              placeholder: 'Enter Your Email Here',
              isRequired: true,
              label: 'Sign in to your account:'
            },
          },
          confirmVerifyUser: {
            confirmation_code: {
              labelHidden: false,
              label: 'New Label',
              placeholder: 'Enter your Confirmation Code:',
              isRequired: true,
            },
          },
          confirmSignIn: {
            confirmation_code: {
              labelHidden: false,
              label: 'New Label',
              placeholder: 'Enter your Confirmation Code:',
              isRequired: false,
            },
          },
          signUp: {
            username: {
              labelHidden: false,
              placeholder: 'Enter Your Email Here',
              isRequired: true,
              label: 'Sign up for a free API Key:'
            },
        }
      }
    };
  },
  async created() {
    try {
      const authUser = await Auth.currentSession()
      if(authUser){
        this.$router.push('/user').catch((e) => {console.log(e)})
      }
    } catch(e){
      console.log(e)
    }
    
    Hub.listen('auth', (data)=>{
      switch(data.payload.event){
        case 'signIn':
          this.$router.push('/user').catch((e) => {console.log(e)})
        case 'authenticated':
          this.$router.push('/user').catch((e) => {console.log(e)})
        case 'configured':
          this.$router.push('/user').catch((e) => {console.log(e)})
      } 
    }) 
  }
};
</script>
<style>
.amplify-authenticator__tabs {
  padding: 10px 10px 10px 0px;
  margin-bottom: -100px !important;
}

[data-amplify-authenticator] [data-amplify-router] {
  height: 480px !important;
  width: 450px !important;
  border-radius: 10px !important;
}

.amplify-field__error-message {
  font-size: 12px;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 15px 40px;

}

.amplify-label {
  text-align: left;
  padding-left: 20px;
  font-size: 14px;
}

.amplify-input {
  padding: 10px;
  margin: 4px;
  border-radius: 4px !important;
  border-width: 1px !important;
  font-size: 12px;
  height: 50px;
  text-align: left;
}

.amplify-field-group__outer-end {
  height: 50px !important;
  margin-top: 5px;
}

.amplify-tabs[data-indicator-position=top] {
  border-top: none !important;
}

.amplify-tabs-item[data-spacing=equal] {
  border-top: none !important;
  border-radius: 10px;
  margin-top: 2px !important;
}

.amplify-tabs-item[data-state=active] {

  border-bottom: 2px #455BAD solid;
  color: #455BAD !important;
  border-top: none !important;
}

.data-amplify-footer {
  background: white !important;
}

.amplify-button {
  background: #455BAD !important;
  color: white !important;
}

.amplify-button--link {
  color: black !important;
  background: white !important;
  font-weight: 300;
}

.authForm {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>
