<template>
    <div
        style="min-width: 400px; text-align: center; max-width: 600px; padding:40px; margin: auto auto; background: #495BAB; border-radius: 10px;">
        <h1 style="color:white;margin-bottom: 40px;">Cancellation Failed</h1>
        <h3 style="color:white; margin-bottom: 40px;"> Cancelling your current plan has failed. Please get in contact
            with us to fix this:</h3>
        <a href="mailto:nick@connect.earth?subject=Error in Cancelling my plan&body=Hi Connect Earth, I am an API user and am having issues with cancelling my current pricing plan, please help me."
            style="display: block; margin: 0 auto; padding:20px; background:#1B2036; width: 200px; text-decoration:none; color: white; border-radius: 10px;">Go
            to Console </a>
    </div>
</template>